@tailwind base;
@tailwind components;
@tailwind utilities;

@import "imports";

@import "security-theme";

@font-face { font-family: Roboto, sans-serif; }

body{
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.5px;
}

.security-content {
  padding: 1.25rem 1rem 1rem 1rem;
  height: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  body {
    .security-content {
      width: 95%;
    }
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  body {
    .security-content {
      width: 90%;
    }
  }
}


.header {
	padding: 1rem;
	height: 5rem;
	box-shadow: rgba(0, 0, 0, 0.15) 0 0.313em 0.938em 0;
}

.copyright {
  margin-bottom: 1rem;
  font-size: 0.75rem;
}



.header-logo {
	background-image: $logo;
	background-repeat: no-repeat;
	margin-right: 10px;
	background-size: contain;
	background-position: center;
	width: 12.5rem;
	height: 3.75rem;
  @apply hover:cursor-pointer;
}




/******  Portal 2.0 Figma Styles *****/

h3{
	color: var(--text-color, rgba(0, 0, 0, 0.87));
	font-size: 3rem;
	font-style: normal;
	font-weight: 400;
	line-height: 3.5rem;
}

h4{
	font-size: 2.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem; /* 105.882% */
}

.page-container{
	@apply h-full flex flex-col justify-start items-stretch gap-[2rem];
}

.container-row{
	@apply flex flex-row justify-start items-center;
}

.section-container{
	@apply flex flex-col justify-start items-stretch gap-[1.375rem];
	@apply p-[2.188rem];
	background: #F2F4F7;
}

.grid-section-container{
	@apply h-[75vh];
}

.content-container-with-max-height{
	@apply h-[55vh];
}

.content-container{
	@apply flex flex-col justify-start items-stretch gap-[1rem];
	background: #fff;
	padding: 24px 25px 25px 25px;
}

kendo-formfield{
	@apply h-[5.375rem];
}

.form-container{
	@apply flex flex-col justify-start items-stretch gap-[1.5rem];
}

.form-row{
	@apply flex flex-row gap-[2rem];
}

.card{
	@apply flex flex-col justify-start items-stretch gap-[1.5rem];
	@apply p-[2.75rem];
	background: #fafafa;

	.card-header{
		font-family: Roboto, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px; /* 125% */
	}
}

.form-label{
	@apply w-full;
}

.form-switch-content{
	@apply items-center gap-[1rem]
}

.action-button-container{
	@apply flex flex-row gap-[1.25rem];
}

.action-button{
	///* @apply w-[10rem]; */
}

.tabstrip-container{
	@apply h-[70vh];
}
.verification-code-textbox{
	@apply w-[12rem];
}

.fit-container{
	width:100%;
	height: 100%;
}


.one-action-button-cell {
	width: 2.5rem;
}

.two-action-button-cell {
	width: 4rem;
}

.three-action-button-cell {
	width: 8.5rem;
}

.content-link{
  font-size: .75rem;
}
.content-link:hover {
	cursor: pointer;
}
